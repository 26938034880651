import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  addSeries() {
    var seriesName = $('#add-series').val();
    $.ajax({
      method: "POST",
      url: "/series",
      data: JSON.stringify({ series: { name: seriesName } }),
      contentType: "application/json; charset=utf-8",
      dataType: 'json',
      success: function(data, status, xhr) {
        $('#find-and-add-series').select2('close');
        var newSeries = new Option(data.name, data.id, true, true);
        $('#find-and-add-series').append(newSeries).trigger('change');
      },
      error: function(data) {
        console.log('Series not created');
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus"

document.addEventListener("turbo:before-cache", () => $('#find-and-add-participants').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));

export default class extends Controller {
  static values = {
    userId: String
  }

  connect() {
    if (document.getElementById("find-and-add-participants")) {
      if ($("#find-and-add-participants").data('select2') == undefined && $("#find-and-add-participants").next().hasClass('select2-container')) {
        $("#find-and-add-participants").next().remove();
      }

      var hasBookClubMeeting = $("#find-and-add-participants").data("has-book-club-meeting");

      var select2Options = {
        width: "100%",
        maximumSelectionLength: 14,
        language: {
          maximumSelected: function (e) {
            var t = "You can only invite up to " + e.maximum + " users.";
            return t;
          }
        },
        escapeMarkup: function(markup) {
          return markup;
        },
        ajax: {
          url: '/buddy-read-participants',
          type: 'GET',
          dataType: 'json',
          processResults: function(data) {
            return {
              results: data.map(function(participant, i) {
                return {
                  id: participant.id,
                  text: participant.name,
                  profilePicture: participant.profile_picture_url
                }
              })
            }
          },
        },
        templateResult: function(participant) {
          if (!participant.id) {
            return participant.text;
          }

          if (!participant.profilePicture) {
            return participant.text;
          }
          var $participant = $(
            '<span><img class="inline-block rounded-full h-6 w-6 mr-1" /> <span></span></span>'
          );

          $participant.find("span").text(participant.text);
          $participant.find("img").attr("src", participant.profilePicture);
          return $participant;
        },
        templateSelection: function(participant) {
          return participant.text;
        },
        delay: 500,
        minimumInputLength: 3
      };

      if (!hasBookClubMeeting) {
        select2Options.maximumSelectionLength = 14;
      }

      $("#find-and-add-participants").select2(select2Options);
    }
  }

  addToParticipants() {
    var userId = this.userIdValue
    var participantSelect = $('#find-and-add-participants');
    $.ajax({
      type: 'GET',
      url: '/buddy-read-participants/' + userId
    }).then(function (participant) {
      var option = new Option(participant.username, participant.id, true, true);
      participantSelect.append(option).trigger('change');

      participantSelect.trigger({
        type: 'select2:select',
        params: {
          data: participant
        }
      });
    });
    $('[data-select2-buddy-reads-user-id-value='+userId+']').hide();
    if (participantSelect.find(':selected').length == 7) {
      $('.add-user-to-buddy-read-icon').hide();
    }
  }
}

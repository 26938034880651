import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  refreshTabs(event) {
    if (!event.detail.success) return

    const signedOut = this.element.dataset.signedOut === "true";
    const signedIn = this.element.dataset.signedIn === "true";

    if (signedOut) {
      this.redirectAllTabsToSignIn();
    } else if (signedIn) {
      this.redirectAllTabsToStartLocation();
    } else {
      this.refreshAllTabs();
    }
  }

  redirectAllTabsToSignIn() {
    if (typeof AndroidRefreshHandler !== 'undefined') {
      AndroidRefreshHandler.redirectToSignIn();
    } else if (navigator.userAgent.match(/Turbo Native iOS Version (\d+)|StoryGraph \(Turbo Native\)/)) {
      window.webkit.messageHandlers.nativeAppViewController.postMessage({ type: 'redirectToSignIn' });
    }
  }

  redirectAllTabsToStartLocation() {
    if (typeof AndroidRefreshHandler !== 'undefined') {
        AndroidRefreshHandler.redirectToStartLocation();
    } else if (navigator.userAgent.match(/Turbo Native iOS Version (\d+)|StoryGraph \(Turbo Native\)/)) {
      window.webkit.messageHandlers.nativeAppViewController.postMessage({ type: 'redirectToStartLocation' });
    }
  }

  refreshAllTabs() {
    if (typeof Android !== 'undefined') {
      const themeSettingElement = document.getElementById('theme_setting');
      if (themeSettingElement) {
        const themeSetting = document.getElementById('theme_setting').value;
        Android.setUserThemeSetting(themeSetting);
      }
    } else if (navigator.userAgent.match(/Turbo Native iOS Version (\d+)|StoryGraph \(Turbo Native\)/)) {
      const themeSettingElement = document.getElementById('theme_setting');
      if (themeSettingElement) {
        const themeSetting = document.getElementById('theme_setting').value;
        window.webkit.messageHandlers.nativeAppViewController.postMessage({ type: 'themeSetting', user_id: themeSetting })
      }
    }

    if (typeof AndroidRefreshHandler !== 'undefined') {
      AndroidRefreshHandler.manualRefreshExceptCurrent();
    } else if (navigator.userAgent.match(/Turbo Native iOS Version (\d+)|StoryGraph \(Turbo Native\)/)) {
      window.webkit.messageHandlers.nativeAppViewController.postMessage({ type: 'reload' });
    }
  }
}

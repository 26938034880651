import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (document.getElementById("content-types-minor")) {
      $("#content-types-minor").select2({
        width: "100%",
        closeOnSelect: false
      });

      $("#content-types-minor").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }

    if (document.getElementById("content-types-moderate")) {
      $("#content-types-moderate").select2({
        width: "100%",
        closeOnSelect: false
      });

      $("#content-types-moderate").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }

    if (document.getElementById("content-types-graphic")) {
      $("#content-types-graphic").select2({
        width: "100%",
        closeOnSelect: false
      });

      $("#content-types-graphic").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }

    if ($("#content-warning-preferences").data('select2') == undefined && $("#content-warning-preferences").next().hasClass('select2-container')) {
      $("#content-warning-preferences").next().remove();
    }

    if (document.getElementById("content-warning-preferences")) {
      $("#content-warning-preferences").select2({
        width: "100%",
        closeOnSelect: false
      });

      $("#content-warning-preferences").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }
  }
}

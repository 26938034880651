import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["typeSelectOne", "typeSelectTwo", "yearOne", "yearTwo", "monthOne", "monthTwo", "customTimePeriodOne", "customTimePeriodTwo", "startDateOne", "startDateTwo", "endDateOne", "endDateTwo", "moodOne", "moodTwo", "bookTypeOne", "bookTypeTwo", "genreOne", "genreTwo", "ratingOne", "ratingTwo", "tagOne", "tagTwo"]

  connect() {
    const selectedTypeOne = this.typeSelectOneTarget.value;
    const selectedTypeTwo = this.typeSelectTwoTarget.value;

    switch(selectedTypeOne) {
      case "Read Books":
        this.show(this.yearOneTarget)
        const selectedYear = this.yearOneTarget.value
        this.toggleVisibilityAndClearValue(this.monthOneTarget, selectedYear === "0" || selectedYear === "-1");
        this.toggleVisibilityAndClearValue(this.customTimePeriodOneTarget, selectedYear !== "-1");
        break;
      case "Moods":
        this.show(this.moodOneTarget)
        break;
      case "Fiction/Nonfiction":
        this.show(this.bookTypeOneTarget)
        break;
      case "Genres":
        this.show(this.genreOneTarget)
        break;
      case "Star ratings":
        this.show(this.ratingOneTarget)
        break;
      case "Tags":
        this.show(this.tagOneTarget)
        break;
    }

    switch(selectedTypeTwo) {
      case "Read Books":
        this.show(this.yearTwoTarget)
        const selectedYear = this.yearTwoTarget.value
        this.toggleVisibilityAndClearValue(this.monthTwoTarget, selectedYear === "0" || selectedYear === "-1");
        this.toggleVisibilityAndClearValue(this.customTimePeriodTwoTarget, selectedYear !== "-1");
        break;
      case "Moods":
        this.show(this.moodTwoTarget)
        break;
      case "Fiction/Nonfiction":
        this.show(this.bookTypeTwoTarget)
        break;
      case "Genres":
        this.show(this.genreTwoTarget)
        break;
      case "Star ratings":
        this.show(this.ratingTwoTarget)
        break;
      case "Tags":
        this.show(this.tagTwoTarget)
        break;
    }

    this.initializeCustomTimePeriodDateValues();
  }

  toggleDropdowns(event) {
    const selectElement = event.target;
    const selectedType = selectElement.value;
    let yearTarget
    let monthTarget
    let customTimePeriodTarget
    let moodTarget
    let bookTypeTarget
    let genreTarget
    let ratingTarget
    let tagTarget

    if (selectElement.id === 'select-stats-type-1') {
      yearTarget = this.yearOneTarget;
      monthTarget = this.monthOneTarget;
      customTimePeriodTarget = this.customTimePeriodOneTarget;
      moodTarget = this.moodOneTarget;
      bookTypeTarget = this.bookTypeOneTarget;
      genreTarget = this.genreOneTarget;
      ratingTarget = this.ratingOneTarget;
      tagTarget = this.tagOneTarget;
    } else if (selectElement.id === 'select-stats-type-2') {
      yearTarget = this.yearTwoTarget;
      monthTarget = this.monthTwoTarget;
      customTimePeriodTarget = this.customTimePeriodTwoTarget;
      moodTarget = this.moodTwoTarget;
      bookTypeTarget = this.bookTypeTwoTarget;
      genreTarget = this.genreTwoTarget;
      ratingTarget = this.ratingTwoTarget;
      tagTarget = this.tagTwoTarget;
    }

    this.hideAndClear(yearTarget)
    this.hideAndClear(monthTarget)
    this.hideAndClear(customTimePeriodTarget)
    this.hideAndClear(moodTarget)
    this.hideAndClear(bookTypeTarget)
    this.hideAndClear(genreTarget)
    this.hideAndClear(ratingTarget)
    this.hideAndClear(tagTarget)

    switch(selectedType) {
      case "Read Books":
        this.show(yearTarget)
        break;
      case "Moods":
        this.show(moodTarget)
        break;
      case "Fiction/Nonfiction":
        this.show(bookTypeTarget)
        break;
      case "Genres":
        this.show(genreTarget)
        break;
      case "Star ratings":
        this.show(ratingTarget)
        break;
      case "Tags":
        this.show(tagTarget)
        break;
    }
  }

  toggleDateDropdowns(event) {
    const setNumber = event.target.dataset.set;

    const selectedYear = event.target.value;

    if (setNumber === "1") {
      this.toggleVisibilityAndClearValue(this.monthOneTarget, selectedYear === "0" || selectedYear === "-1");
      this.toggleVisibilityAndClearValue(this.customTimePeriodOneTarget, selectedYear !== "-1");
    } else if (setNumber === "2") {
      this.toggleVisibilityAndClearValue(this.monthTwoTarget, selectedYear === "0" || selectedYear === "-1");
      this.toggleVisibilityAndClearValue(this.customTimePeriodTwoTarget, selectedYear !== "-1");
    }
  }

  hideAndClear(element) {
    element.classList.add("hidden");
    if (element.id == "select-stats-custom-time-period-1") {
      const flatpickrAltInput = this.element.querySelector('.flatpickr-alt-input-1');
      if (flatpickrAltInput) {
        this.hideAndClear(flatpickrAltInput);
      }
    }
    if (element.id == "select-stats-custom-time-period-2") {
      const flatpickrAltInput = this.element.querySelector('.flatpickr-alt-input-2');
      if (flatpickrAltInput) {
        this.hideAndClear(flatpickrAltInput);
      }
    }
    element.value = "";
  }

  show(element) {
    element.classList.remove("hidden");
  }

  toggleVisibilityAndClearValue(element, shouldBeHidden) {
    if (shouldBeHidden) {
      this.hideAndClear(element);
      if (element.id == "select-stats-custom-time-period-1") {
        if (this.flatpickrOne) {
          this.flatpickrOne.clear();
        }
      }
      if (element.id == "select-stats-custom-time-period-2") {
        if (this.flatpickrTwo) {
          this.flatpickrTwo.clear();
        }
      }
    } else {
      this.show(element);
      if (element.id == "select-stats-custom-time-period-1") {
        this.initializeCustomTimePeriodPicker(element.id);
      }
      if (element.id == "select-stats-custom-time-period-2") {
        this.initializeCustomTimePeriodPicker(element.id);
      }
    }
  }

  initializeCustomTimePeriodPicker(elementId) {
    if (elementId == "select-stats-custom-time-period-1") {
      const urlParams = new URLSearchParams(window.location.search);
      const selectedYearOne = urlParams.get('year_1');
      const startDateOne = urlParams.get('start_date_1');
      const endDateOne = urlParams.get('end_date_1');

      if (this.customTimePeriodOneTarget.nextElementSibling?.classList.contains('flatpickr-alt-input-1')) {
        this.customTimePeriodOneTarget.nextElementSibling?.remove();
      }

      if (this.flatpickrOne) {
        this.flatpickrOne.clear();
      }

      const userID = this.yearOneTarget.getAttribute('user_id');
      const earliestYear = this.customTimePeriodOneTarget.dataset.earliestYear;
      const minDate = `${earliestYear}-01-01`;
      const maxDate = new Date();

      this.flatpickrOne = flatpickr(this.customTimePeriodOneTarget, {
        mode: "range",
        dateFormat: "Y-m-d",
        minDate: minDate,
        maxDate: maxDate,
        defaultDate: [startDateOne, endDateOne],
        altInput: true,
        altInputClass: "mt-1 bg-transparent border-darkGrey dark:border-darkerGrey rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-cyan-600 dark:focus:ring-cyan-400 focus:border-cyan-700 dark:focus:border-cyan-500 text-sm w-11 text-center flatpickr-alt-input-1",
        altFormat: "j M y",
        onChange: (selectedDates) => {
          if (selectedDates.length === 2) {
            const startDate = flatpickr.formatDate(selectedDates[0], "Y-m-d");
            const endDate = flatpickr.formatDate(selectedDates[1], "Y-m-d");
            this.startDateOneTarget.value = startDate;
            this.endDateOneTarget.value = endDate;
          }
        }
      });
    }

    if (elementId == "select-stats-custom-time-period-2") {
      const urlParams = new URLSearchParams(window.location.search);
      const selectedYearTwo = urlParams.get('year_2');
      const startDateTwo = urlParams.get('start_date_2');
      const endDateTwo = urlParams.get('end_date_2');

      if (this.customTimePeriodTwoTarget.nextElementSibling?.classList.contains('flatpickr-alt-input-2')) {
        this.customTimePeriodTwoTarget.nextElementSibling?.remove();
      }

      if (this.flatpickrTwo) {
        this.flatpickrTwo.clear();
      }

      const userID = this.yearTwoTarget.getAttribute('user_id');
      const earliestYear = this.customTimePeriodTwoTarget.dataset.earliestYear;
      const minDate = `${earliestYear}-01-01`;
      const maxDate = new Date();

      this.flatpickrTwo = flatpickr(this.customTimePeriodTwoTarget, {
        mode: "range",
        dateFormat: "Y-m-d",
        minDate: minDate,
        maxDate: maxDate,
        defaultDate: [startDateTwo, endDateTwo],
        altInput: true,
        altInputClass: "mt-1 bg-transparent border-darkGrey dark:border-darkerGrey rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-cyan-600 dark:focus:ring-cyan-400 focus:border-cyan-700 dark:focus:border-cyan-500 text-sm w-11 text-center flatpickr-alt-input-2",
        altFormat: "j M y",
        onChange: (selectedDates) => {
          if (selectedDates.length === 2) {
            const startDate = flatpickr.formatDate(selectedDates[0], "Y-m-d");
            const endDate = flatpickr.formatDate(selectedDates[1], "Y-m-d");
            this.startDateTwoTarget.value = startDate;
            this.endDateTwoTarget.value = endDate;
          }
        }
      });
    }
  }

  initializeCustomTimePeriodDateValues() {
    if (this.hasCustomTimePeriodOneTarget) {
      const urlParams = new URLSearchParams(window.location.search);
      const startDateOne = urlParams.get('start_date_1');
      const endDateOne = urlParams.get('end_date_1');
      if (startDateOne) {
        this.startDateOneTarget.value = startDateOne;
      }
      if (endDateOne) {
        this.endDateOneTarget.value = endDateOne;
      }
    }

    if (this.hasCustomTimePeriodTwoTarget) {
      const urlParams = new URLSearchParams(window.location.search);
      const startDateTwo = urlParams.get('start_date_2');
      const endDateTwo = urlParams.get('end_date_2');
      if (startDateTwo) {
        this.startDateTwoTarget.value = startDateTwo;
      }
      if (endDateTwo) {
        this.endDateTwoTarget.value = endDateTwo;
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"

document.addEventListener("turbo:before-cache", () => $('#filter-by-user-tags').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));

document.addEventListener("turbo:before-cache", () => $('#filter-by-user-tags-exclude').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));

document.addEventListener("turbo:before-cache", () => $('.edit-user-tags-on-book').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));


export default class extends Controller {
  connect() {
    if ($("#filter-by-user-tags").data('select2') == undefined && $("#filter-by-user-tags").next().hasClass('select2-container')) {
      $("#filter-by-user-tags").next().remove();
    }

    if ($("#filter-by-user-tags-exclude").data('select2') == undefined && $("#filter-by-user-tags-exclude").next().hasClass('select2-container')) {
      $("#filter-by-user-tags-exclude").next().remove();
    }

    if ($(".edit-user-tags-on-book").data('select2') == undefined && $(".edit-user-tags-on-book").next().hasClass('select2-container')) {
      $(".edit-user-tags-on-book").next().remove();
    }

    $("#filter-by-user-tags").select2({
      width: "100%",
      closeOnSelect: false
    });

    $("#filter-by-user-tags-exclude").select2({
      width: "100%",
      closeOnSelect: false
    });

    $("#filter-by-user-tags").on('select2:select', function(e){
      var id = e.params.data.id;
      var option = $(e.target).children('[value='+id+']');
      option.detach();
      $(e.target).append(option).change();
    });

    $("#filter-by-user-tags-exclude").on('select2:select', function(e){
      var id = e.params.data.id;
      var option = $(e.target).children('[value='+id+']');
      option.detach();
      $(e.target).append(option).change();
    });
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["userAdminMenuMobile", "userAdminMenuDesktop"];

  toggleMenu() {
    $('#reading-challenge-admin-menu-mobile').toggle();
    $('#reading-challenge-admin-menu-desktop').toggle();
  }

  toggleUserAdminMenu() {
    this.userAdminMenuMobileTarget.classList.toggle("hidden");
    this.userAdminMenuDesktopTarget.classList.toggle("hidden");
  }
}

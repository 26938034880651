import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    userId: String
  }

  connect() {
    let data = new FormData()
    data.append("userId", this.userIdValue )

    Rails.ajax({
      url: '/mark-as-read',
      type: 'POST',
      data: data
    })
  }
}

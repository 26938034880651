import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if ($(".find-and-add-contributor").data('select2') == undefined && $(".find-and-add-contributor").next().hasClass('select2-container')) {
      $(".find-and-add-contributor").next().remove();
    }

    $('.find-and-add-contributor').select2({
      width: "100%",
      allowClear: true,
      placeholder: 'Search contributor name...',
      escapeMarkup: function(markup) {
        return markup;
      },
      ajax: {
        url: '/authors',
        type: 'GET',
        dataType: 'json',
        processResults: function(data) {
          return {
            results: data.map(function(author, i) {
              return {
                id: author.id,
                text: author.name
              }
            })
          }
        },
      },
      templateResult: function(author) {
        if (!author.id) {
          return author.text;
        }
        var $author = author.text + '<br />' + author.id.substring(0,5)
        return $author;
      },
      templateSelection: function(author) {
        return author.text;
      },
      delay: 500,
      minimumInputLength: 3
    });

    $('.find-and-add-contributor').on('select2:open', function (e) {
      var uId = $(this).attr('id');
      $('.add-contributor-container').remove();
      $(".select2-results:not(:has(a))").append('<div class="add-contributor-container" id="latest-contributor-container" style="margin-left:10px;margin-right:10px"><hr><p class="mt-2 text-sm">..or add a new contributor if the one you\'re looking for isn\'t in our database:</p><input id="add-contributor" class="mt-1 block w-full bg-white dark:bg-darkestGrey border border-darkerGrey dark:border-lightGrey rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-cyan-600 dark:focus:ring-cyan-400 focus:border-cyan-700 dark:focus:border-cyan-500 sm:text-sm" type="text" placeholder="Add a new contributor"></input><br /><div data-controller="authors"><button data-action="authors#addContributor" id="add-contributor-submit" class="cursor-pointer inline-flex secondary-btn -mt-2 mb-3">Add contributor</button></div></div>')
      document.getElementById("latest-contributor-container").classList.add(uId)
    });

    $('#add-contributor-fields').on('click', '.add_fields', function(){
      const container = document.querySelector("#add-contributor-fields");
      const selectContributorFields = container.querySelectorAll(".find-and-add-contributor");
      selectContributorFields.forEach(function(contributorField) {
        $(contributorField).select2({
        width: "100%",
          allowClear: true,
          placeholder: 'Search contributor name...',
          escapeMarkup: function(markup) {
            return markup;
          },
          ajax: {
            url: '/authors',
            type: 'GET',
            dataType: 'json',
            processResults: function(data) {
              return {
                results: data.map(function(author, i) {
                  return {
                    id: author.id,
                    text: author.name
                  }
                })
              }
            },
          },
          templateResult: function(author) {
            if (!author.id) {
              return author.text;
            }
            var $author = author.text + '<br />' + author.id.substring(0,5)
            return $author;
          },
          templateSelection: function(author) {
            return author.text;
          },
          delay: 500,
          minimumInputLength: 3
        });
      });
      $('.find-and-add-contributor').on('select2:open', function (e) {
        var uId = $(this).attr('id');
        $('.add-contributor-container').remove();
        $(".select2-results:not(:has(a))").append('<div class="add-contributor-container" id="latest-contributor-container" style="margin-left:10px;margin-right:10px"><hr><p class="mt-2 text-sm">..or add a new contributor if the one you\'re looking for isn\'t in our database:</p><input id="add-contributor" class="mt-1 block w-full bg-white dark:bg-darkestGrey border border-darkerGrey dark:border-lightGrey rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-cyan-600 dark:focus:ring-cyan-400 focus:border-cyan-700 dark:focus:border-cyan-500 sm:text-sm" type="text" placeholder="Add a new contributor"></input><br /><div data-controller="authors"><button data-action="authors#addContributor" id="add-contributor-submit" class="cursor-pointer -mt-2 mb-3 inline-flex secondary-btn">Add contributor</button></div></div>')
      document.getElementById("latest-contributor-container").classList.add(uId)
      });
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import Combobox from "@github/combobox-nav"

export default class extends Controller {
  static targets = [ "input", "list" ]

  disconnect() {
    this.combobox?.destroy()
  }

  listTargetConnected() {
    this.start()
  }

  collapseListTarget() {
    this.listTarget.hidden = true
  }

  start() {
    this.combobox?.destroy()
    this.listTarget.hidden = false
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
    window.clickOutSideMemberSearchBooks = (element, collapseListTarget) => {
      document.addEventListener('click', (event) => {
        if (!element.contains(event.target)) {
          collapseListTarget();
        }
      });
    };
    window.clickOutSideMemberSearchBooks(document.querySelector('#book-club-search-members'), () => this.collapseListTarget());
  }

  stop() {
    this.combobox?.stop()
  }
}

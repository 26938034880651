import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pollOptionButton", "votesUserListModal", "voteUserList"];

  openVotesUserList() {
    let pollId = event.currentTarget.dataset.pollId;
    this.data.set("poll-id", pollId);
    this.votesUserListModalTarget.classList.remove("hidden");
    document.body.classList.add('overflow-hidden')
    this.setActivePollOption(this.pollOptionButtonTargets[0]);
    this.loadVotesContent(this.pollOptionButtonTargets[0].dataset.pollOptionId);
  }

  selectPollOption(event) {
    const button = event.currentTarget;
    this.setActivePollOption(button);
    this.loadVotesContent(button.dataset.pollOptionId);
  }

  setActivePollOption(button) {
    this.pollOptionButtonTargets.forEach(btn => btn.classList.remove('bg-darkGrey', 'dark:bg-darkerGrey'));
    button.classList.add('bg-darkGrey', 'dark:bg-darkerGrey');
  }

  loadVotesContent(poll_option_id) {
    const pollId = this.data.get("poll-id");
    const url = `/votes?poll_id=${pollId}&poll_option_id=${poll_option_id}`;

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then(html => {
        this.voteUserListTarget.innerHTML = html;
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }

  closeVotesUserList() {
    this.votesUserListModalTarget.classList.add("hidden");
    document.body.classList.remove('overflow-hidden')
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (document.getElementById("select-edition-languages")) {
      if ($("#select-edition-languages").data('select2') == undefined && $("#select-edition-languages").next().hasClass('select2-container')) {
        $('#select-edition-languages').next().remove();
      }

      $("#select-edition-languages").select2({
        width: "100%",
        closeOnSelect: false
      });
    }

    if (document.getElementById("select-languages")) {
      if ($("#select-languages").data('select2') == undefined) {
        $("#select-languages").next().remove();
      }

      if (document.getElementById("select-languages")) {
        $("#select-languages").select2({
          width: "100%",
          closeOnSelect: false
        }).val(userLanguages());
      }

      $("#select-languages").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });

      function userLanguages() {
        $.ajax({
          url: "/user_languages",
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          success: function(data, status, xhr) {
            $("#select-languages").val(data).trigger('change');
          }
        });
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import Combobox from "@github/combobox-nav"

export default class extends Controller {
  static targets = [ "input", "list" ]
  static values = { bookClubId: String, bookClubMeetingId: String  }

  connect() {
    this.inputTarget.addEventListener('keypress', event => this.handleKeyPress(event));
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.submitSearch();
    }
  }

  disconnect() {
    this.combobox?.destroy()
  }

  listTargetConnected() {
    this.start()
  }

  collapseListTarget() {
    this.listTarget.hidden = true
  }

  start() {
    this.combobox?.destroy()
    this.listTarget.hidden = false
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
    window.clickOutSideMeetingHostSearchBooks = (element, collapseListTarget) => {
      document.addEventListener('click', (event) => {
        if (!element.contains(event.target)) {
          collapseListTarget();
        }
      });
    };
    window.clickOutSideMeetingHostSearchBooks(document.querySelector('#meeting-host-search'), () => this.collapseListTarget());
  }

  submit() {
    const searchTerm = this.inputTarget.value
    const bookClubId = this.bookClubIdValue;
    const bookClubMeetingId = this.bookClubMeetingIdValue;

    fetch(`/book_clubs/${bookClubId}/meetings/${bookClubMeetingId}/host_search?meeting_host_search_term=${encodeURIComponent(searchTerm)}`, {
      method: 'GET',
    })
      .then(response => response.text())
      .then(html => {
        this.element.querySelector('#meeting-host-search-results').innerHTML = html;
      });
  }

  stop() {
    this.combobox?.stop()
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  addPublisher() {
    var publisherName = $('#add-publisher').val();
    $.ajax({
      method: "POST",
      url: "/publishers",
      data: JSON.stringify({ publisher: { name: publisherName } }),
      contentType: "application/json; charset=utf-8",
      dataType: 'json',
      success: function(data, status, xhr) {
        $('#find-and-add-publisher').select2('close');
        var newPublisher = new Option(data.name, data.id, true, true);
        $('#find-and-add-publisher').append(newPublisher).trigger('change');
      },
      error: function(data) {
        console.log('Publisher not created');
      }
    });
  }
}

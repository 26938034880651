import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "cameraModal",
    "cameraModalOwned",
    "video",
    "videoOwned",
    "canvas",
    "canvasOwned",
    "captureButton",
    "captureButtonOwned",
    "useNativeCameraButton",
    "useNativeCameraButtonOwned",
    "nativeCameraUpload",
    "nativeCameraUploadOwned"
  ];

  mediaStream = null;
  mediaStreamOwned = null;

  async connect() {
    this.video = document.getElementById("camera-preview");
    this.videoOwned = document.getElementById("camera-preview-owned");
    this.canvas = document.getElementById("captured-image");
    this.canvasOwned = document.getElementById("captured-image-owned");
    this.captureButton = document.getElementById("capture-button");
    this.captureButtonOwned = document.getElementById("capture-button-owned");
    this.cameraModal = document.getElementById("camera-modal");
    this.cameraModalOwned = document.getElementById("camera-modal-owned");
    this.useNativeCameraButton = document.getElementById("use-native-camera-button");
    this.useNativeCameraButtonOwned = document.getElementById("use-native-camera-button-owned");
    this.nativeCameraUpload = document.getElementById("native-camera-upload");
    this.nativeCameraUploadOwned = document.getElementById("native-camera-upload-owned");
  }

  async openCameraModal() {
    this.cameraModal.classList.remove("hidden");
    document.body.classList.add("overflow-hidden");

    if (this.mediaStream?.active) {
      this.video.srcObject = this.mediaStream;
      this.video.play();
      return;
    }

    await this.startCamera(false);
  }

  async openCameraModalOwned() {
    this.cameraModalOwned.classList.remove("hidden");
    document.body.classList.add("overflow-hidden");

    if (this.mediaStreamOwned?.active) {
      this.videoOwned.srcObject = this.mediaStreamOwned;
      this.videoOwned.play();
      return;
    }

    await this.startCamera(true);
  }

  triggerNativeCamera(event) {
    const isOwned = event.currentTarget.dataset.owned === "true";
    const fileInput = isOwned ? this.nativeCameraUploadOwned : this.nativeCameraUpload;
    fileInput?.click();
  }

  handleNativeUpload(event) {
    const isOwned = event.currentTarget.id.includes("owned");
    const file = event.currentTarget.files[0];
    if (!file) return;

    const canvasElement = isOwned ? this.canvasOwned : this.canvas;
    const captureButton = isOwned ? this.captureButtonOwned : this.captureButton;
    const videoElement = isOwned ? this.videoOwned : this.video;

    const img = new Image();
    const context = canvasElement.getContext("2d");

    img.onload = () => {

      const aspectRatio = img.width / img.height;
      let canvasWidth, canvasHeight;

      if (aspectRatio > 1) {
        canvasWidth = Math.min(img.width, 1920);
        canvasHeight = canvasWidth / aspectRatio;
      } else {
        canvasHeight = Math.min(img.height, 1920);
        canvasWidth = canvasHeight * aspectRatio;
      }

      canvasElement.width = canvasWidth;
      canvasElement.height = canvasHeight;

      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.drawImage(img, 0, 0, canvasWidth, canvasHeight);

      if (videoElement.srcObject) {
        videoElement.srcObject.getTracks().forEach(track => {
          track.stop();
        });
        videoElement.srcObject = null;
      }

      videoElement.classList.add("hidden");
      canvasElement.classList.remove("hidden");

      captureButton.innerText = "Processing...";
      captureButton.disabled = true;

      setTimeout(() => {
        this.processCanvasImage(isOwned);
      }, 100);
    };

    img.onerror = (err) => {
      alert("Failed to load image. Please try again.");
    };

    img.src = URL.createObjectURL(file);
    event.currentTarget.value = ""; // Reset file input for future uploads
  }

  processCanvasImage(isOwned) {
    const canvasElement = isOwned ? this.canvasOwned : this.canvas;
    const imageData = canvasElement.toDataURL("image/jpeg", 0.6);

    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content") || "";

    fetch("/pre_scan", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: JSON.stringify({ image: imageData })
    })
      .then(response => {
        if (!response.ok) throw new Error(`API request failed with status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        const result = data.result || "x";
        if (result === "x") {
          alert("We're sorry, there was a problem. Please try again!");
          this.restartCamera(isOwned);
          return;
        }

        const endpoint = isOwned ? "/scan-to-owned" : "/scan";
        return fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          body: JSON.stringify({ isbn: result })
        })
          .then(response => {
            if (!response.ok) throw new Error(`Endpoint failed with status: ${response.status}`);
            if (isOwned) {
              return response.text().then(html => {
                Turbo.renderStreamMessage(html);
                this.closeOwnedCameraModal();
              });
            } else {
              return response.json().then(data => {
                this.closeCameraModal();
                if (typeof Turbo !== "undefined") {
                  Turbo.visit(data.url);
                } else {
                  window.location.href = data.url;
                }
              });
            }
          });
      })
      .catch(error => {
        alert("We're sorry, there was a problem. Please try again!");
        this.restartCamera(isOwned);
      });
  }

  async startCamera(isOwned = false) {
    try {

      const constraints = { video: { facingMode: "environment" } };

      if (isOwned) {
        this.mediaStreamOwned?.getTracks().forEach(track => track.stop());
        this.mediaStreamOwned = await navigator.mediaDevices.getUserMedia(constraints);
        this.videoOwned.srcObject = this.mediaStreamOwned;
        await this.videoOwned.play();
      } else {
        this.mediaStream?.getTracks().forEach(track => track.stop());
        this.mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.video.srcObject = this.mediaStream;
        await this.video.play();
      }

    } catch (err) {
      alert("We could not access the camera. Please check your device permissions.");
    }
  }

  captureImage(event) {
    const isOwned = event.currentTarget.dataset.owned === "true";

    const videoElement = isOwned ? this.videoOwned : this.video;
    const canvasElement = isOwned ? this.canvasOwned : this.canvas;
    const captureButton = isOwned ? this.captureButtonOwned : this.captureButton;

    const context = canvasElement.getContext("2d");
    const width = videoElement.videoWidth;
    const height = videoElement.videoHeight;

    canvasElement.width = width;
    canvasElement.height = height;

    context.drawImage(videoElement, 0, 0, width, height);

    videoElement.classList.add("hidden");
    canvasElement.classList.remove("hidden");
    captureButton.innerText = "Processing...";
    captureButton.disabled = true;

    this.processCanvasImage(isOwned);
  }

  restartCamera(isOwned = false) {
    const canvasElement = isOwned ? this.canvasOwned : this.canvas;
    const videoElement = isOwned ? this.videoOwned : this.video;
    const captureButton = isOwned ? this.captureButtonOwned : this.captureButton;

    canvasElement.classList.add("hidden");
    videoElement.classList.remove("hidden");
    captureButton.innerText = "Capture";
    captureButton.disabled = false;
    videoElement.play();
  }

  closeModal(isOwned = false) {
    if (isOwned && this.cameraModalOwned) {
      this.videoOwned.pause();
      this.cameraModalOwned.classList.add("hidden");
    } else if (this.cameraModal) {
      this.video.pause();
      this.cameraModal.classList.add("hidden");
    }
    document.body.classList.remove("overflow-hidden");
  }

  closeCameraModal() {
    this.closeModal(false);
    this.restartCamera(false);
  }

  closeOwnedCameraModal() {
    this.closeModal(true);
    this.restartCamera(true);
  }

  disconnect() {
    this.mediaStream?.getTracks().forEach(track => track.stop());
    this.mediaStream = null;
    this.mediaStreamOwned?.getTracks().forEach(track => track.stop());
    this.mediaStreamOwned = null;
  }
}

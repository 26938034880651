import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bookSelection", "bookPollNote", "dateSelection", "datePollNote"]

  toggleMenu() {
    $('#book-club-meeting-admin-menu').toggle();
  }

  toggleBookSelection(event) {
    const selection = event.target.value === "true";
    this.bookSelectionTarget.classList.toggle("hidden", selection);
    this.bookPollNoteTarget.classList.toggle("hidden", !selection);
  }

  toggleDateSelection(event) {
    const selection = event.target.value === "true";
    this.dateSelectionTarget.classList.toggle("hidden", selection);
    this.datePollNoteTarget.classList.toggle("hidden", !selection);
  }
}

import { Controller } from "@hotwired/stimulus"

document.addEventListener("turbo:before-cache", () => $('#filter-by-genre-input-include').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));

document.addEventListener("turbo:before-cache", () => $('#filter-by-genre-input-exclude').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));

document.addEventListener("turbo:before-cache", () => $('#select-similar-users-genres').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));


export default class extends Controller {
  connect() {
    if (document.getElementById("filter-by-genre-input-include")) {
      if ($("#filter-by-genre-input-include").data('select2') == undefined && $("#filter-by-genre-input-include").next().hasClass('select2-container')) {
        $('#filter-by-genre-input-include').next().remove();
      }

      $("#filter-by-genre-input-include").select2({
        width: "100%",
        closeOnSelect: false
      });

      $("#filter-by-genre-input-include").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });

    }

    if (document.getElementById("filter-by-genre-input-exclude")) {
      if ($("#filter-by-genre-input-exclude").data('select2') == undefined && $("#filter-by-genre-input-exclude").next().hasClass('select2-container')) {
        $('#filter-by-genre-input-exclude').next().remove();
      }

      $("#filter-by-genre-input-exclude").select2({
        width: "100%",
        closeOnSelect: false
      });

      $("#filter-by-genre-input-exclude").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }

    if (document.getElementById("select-genres")) {
      if ($("#select-genres").data('select2') == undefined) {
        $("#select-genres").next().remove();
      }

      $("#select-genres").select2({
        width: "100%",
        closeOnSelect: false
      });

      var relatedBook = document.getElementById("related_book_id");
      if (relatedBook) {
        var bookId = relatedBook.getAttribute("value");
        $("#select-genres").val(getRelatedBookGenres(bookId));
      }

      $("#select-genres").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });

      function getRelatedBookGenres(bookId) {
        $.ajax({
          url: "/related_book_genres/" + bookId,
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          success: function(data, status, xhr) {
            $("#select-genres").val(data).trigger('change');
          }
        });
      }
    }

    if (document.getElementById("select-similar-users-genres")) {
      if ($("#select-similar-users-genres").data('select2') == undefined) {
        $("#select-similar-users-genres").next().remove();
      }

      $("#select-similar-users-genres").select2({
        width: "100%",
        maximumSelectionLength: 5,
        closeOnSelect: false
      });

      $("#select-similar-users-genres").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick.bind(this))
  }

  handleClick(event) {
    let target = event.target.closest('.book-prompt-book-list-option');
    if (target) {
      target.closest('li').classList.add('opacity-50', 'cursor-default', 'pointer-events-none');
      var closestSearchBar = target.closest('.book-prompt-search-books');
      if (closestSearchBar) {
        closestSearchBar.style.display = 'none';
      }
    }
  }
}

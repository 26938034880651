import { Controller } from "@hotwired/stimulus"

$(document).on('change', '#reading_challenge_category_ids', function(e) {
  var selectedCategory = $( "#reading_challenge_category_ids option:selected" ).html();
  if(selectedCategory.indexOf("Genres") >= 0){
    $(".select-reading-challenge-genres").removeClass('hidden')
  }
  else{
    $(".select-reading-challenge-genres").addClass('hidden')
    $("#select-reading-challenge-genres").val(null).trigger('change');
  }
});

document.addEventListener("turbo:before-cache", () => $('#filter-by-reading-challenge-keyword').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));

document.addEventListener("turbo:before-cache", () => $('#filter-by-reading-challenge-genre').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option').attr('selected', null);
  if (selected) {
    if ($.isNumeric(selected)) {
      el.find('option[value='+selected+']').attr('selected', true);
    } else {
      $.each(selected, function( index, value ) {
        el.find('option[value='+value+']').attr('selected', true);
      })
    }
  }
}));

export default class extends Controller {
  connect() {
    if ($("#select-reading-challenge-genres").data('select2') == undefined && $("#select-reading-challenge-genres").next().hasClass('select2-container')) {
      $("#select-reading-challenge-genres").next().remove();
    }

    $("#select-reading-challenge-genres").select2({
      width: "100%",
      maximumSelectionLength: 3,
      closeOnSelect: false
    });

    $("#select-reading-challenge-genres").on('select2:select', function(e){
      var id = e.params.data.id;
      var option = $(e.target).children('[value='+id+']');
      option.detach();
      $(e.target).append(option).change();
    });

    if (document.getElementById("filter-by-reading-challenge-keyword")) {
      if ($("#filter-by-reading-challenge-keyword").data('select2') == undefined && $("#filter-by-reading-challenge-keyword").next().hasClass('select2-container')) {
        $("#filter-by-reading-challenge-keyword").next().remove();
      }

      $("#filter-by-reading-challenge-keyword").select2({
        width: "100%",
        closeOnSelect: false
      });

      $("#filter-by-reading-challenge-keyword").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }

    if ($("#filter-by-reading-challenge-genre").data('select2') == undefined && $("#filter-by-reading-challenge-genre").next().hasClass('select2-container')) {
      $("#filter-by-reading-challenge-genre").next().remove();
    }

    $("#filter-by-reading-challenge-genre").select2({
      width: "100%",
      closeOnSelect: false
    });

    $("#filter-by-reading-challenge-genre").on('select2:select', function(e){
      var id = e.params.data.id;
      var option = $(e.target).children('[value='+id+']');
      option.detach();
      $(e.target).append(option).change();
    });

    if (document.getElementById("select-keywords")) {
      if ($("#select-keywords").data('select2') == undefined && $("#select-keywords").next().hasClass('select2-container')) {
        $("#select-keywords").next().remove();
      }

      $("#select-keywords").select2({
        width: "100%",
        maximumSelectionLength: 3,
        closeOnSelect: false
      });

      $("#select-keywords").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }
  }
}

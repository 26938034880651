import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = { handleClass: String }

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: `.${this.handleClassValue}`, // Use the handle class from the data attribute
      delay: 100, // time in milliseconds to define when the sorting should start
      delayOnTouchOnly: true, // only delay if user is using touch
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let item = event.item
    let id = item.dataset.id
    let url = this.data.get("url").replace(":id", id)
    let data = new FormData()
    data.append("position", event.newIndex + 1)

    Rails.ajax({
      url: url,
      type: 'POST',
      data: data
    })
  }
}
